import React from "react";

const LinkIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.45 12.9745L14.2127 11.7373L15.45 10.5C15.775 10.175 16.0328 9.78914 16.2087 9.36449C16.3846 8.93984 16.4751 8.4847 16.4751 8.02507C16.4751 7.56543 16.3846 7.11029 16.2087 6.68564C16.0328 6.26099 15.775 5.87514 15.45 5.55013C15.125 5.22511 14.7391 4.9673 14.3145 4.7914C13.8898 4.61551 13.4347 4.52498 12.975 4.52498C12.5154 4.52498 12.0603 4.61551 11.6356 4.7914C11.211 4.9673 10.8251 5.22511 10.5001 5.55013L9.26285 6.78738L8.0256 5.55013L9.26285 4.31288C10.2501 3.34156 11.5812 2.7997 12.9662 2.80534C14.3512 2.81098 15.6778 3.36366 16.6571 4.34299C17.6364 5.32232 18.1891 6.64895 18.1948 8.03392C18.2004 9.41889 17.6585 10.75 16.6872 11.7373L15.45 12.9745V12.9745ZM12.9746 15.4499L11.7374 16.6871C11.2513 17.1812 10.6723 17.5741 10.0336 17.8432C9.39496 18.1123 8.70934 18.2523 8.01631 18.2551C7.32328 18.2579 6.63655 18.1235 5.99573 17.8596C5.3549 17.5957 4.77267 17.2075 4.28262 16.7175C3.79257 16.2274 3.4044 15.6452 3.14049 15.0044C2.87658 14.3636 2.74216 13.6768 2.74498 12.9838C2.7478 12.2908 2.88781 11.6051 3.15693 10.9665C3.42605 10.3278 3.81895 9.7488 4.31298 9.26275L5.55023 8.0255L6.78748 9.26275L5.55023 10.5C5.22521 10.825 4.9674 11.2109 4.7915 11.6355C4.61561 12.0602 4.52508 12.5153 4.52508 12.9749C4.52508 13.4346 4.61561 13.8897 4.7915 14.3144C4.9674 14.739 5.22521 15.1249 5.55023 15.4499C5.87524 15.7749 6.26109 16.0327 6.68574 16.2086C7.11039 16.3845 7.56553 16.475 8.02517 16.475C8.4848 16.475 8.93994 16.3845 9.36459 16.2086C9.78924 16.0327 10.1751 15.7749 10.5001 15.4499L11.7374 14.2126L12.9746 15.4499ZM12.9746 6.78738L14.2127 8.0255L8.0256 14.2118L6.78748 12.9745L12.9746 6.78825V6.78738Z"
        fill="#163152"
      />
    </svg>
  );
};
export default LinkIcon;
