import React, { useState, useEffect } from "react";
// import { Table } from "react-bootstrap";
import FormikControl from "../component/form/FormikControl";
import { Formik, Form } from "formik";
import LinkButton from "../component/form/LinkButton";
import axios from "axios";

const NewsLetter = (props) => {
  const { t } = props;

  const [subscribers, setSubscibers] = useState([]);

  const config = JSON.parse(localStorage.getItem("headers"));

  const getAllSettings = async () => {
    props.onStartRequest(true);

    try {
      axios
        .get("https://swipyy.com/api/user/subscribers", config)
        .then((res) => {
          setSubscibers(res.data.data.table);
          props.onFinishRequest(true);
        });
    } catch (error) {}
  };

  useEffect(() => {
    getAllSettings();
  }, []);

  const exportCSV = async () => {
    try {
      axios
        .get("https://swipyy.com/api/user/subscribers/export", {
          ...config,
          responseType: "blob",
        })

        .then((blob) => {
          const href = window.URL.createObjectURL(blob.data);
          const a = document.createElement("a");
          a.download = "subscribers.csv";
          a.href = href;
          a.click();
          a.href = "";
        });
    } catch (error) {}
  };
  // return 404 if not found
  return (
    <>
      <div className="index">
        <div className="index-header">
          <p className="your-links-header mb-3">{t("newsletter.title")}</p>
          <LinkButton
            type=""
            buttontext={t("messages.export")}
            exportIcon="true"
            onClick={() => exportCSV()}
          />
        </div>
        <div className="index-content">
          <table className="table">
            <thead>
              <tr>
                <th>{t("newsletter.type")}</th>

                <th>
                  {t("messages.email")} {t("login.or")} {t("messages.phone")}
                </th>
              </tr>
            </thead>
            <tbody>
              {subscribers.map((message) => (
                <tr>
                  <td>{t("newsletter." + message.type)}</td>

                  <td>{message.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default NewsLetter;
