import React, { useState, useEffect } from "react"
// import { Table } from "react-bootstrap";
import LinkButton from "../component/form/LinkButton"
import axios from "axios"
import Deleteicon from "../component/icons/Deleteicon"
import VisibilityIcon from "@mui/icons-material/Visibility"
const Favorite = (props) => {
  const { t } = props

  const [favorite, setFavorite] = useState([])

  const config = JSON.parse(localStorage.getItem("headers"))

  const getAllSettings = async () => {
    props.onStartRequest(true)

    try {
      axios.get("https://swipyy.com/api/user/favorite", config).then((res) => {
        setFavorite(res.data.data)
        props.onFinishRequest(true)
      })
    } catch (error) {}
  }
  const handleEditData = (key, e) => {
    getAllSettings()
  }
  useEffect(() => {
    getAllSettings()
  }, [])

  const exportCSV = async () => {
    try {
      axios
        .get("https://swipyy.com/api/user/favorite/export", {
          ...config,
          responseType: "blob",
        })

        .then((blob) => {
          const href = window.URL.createObjectURL(blob.data)
          const a = document.createElement("a")
          a.download = "favorite.csv"
          a.href = href
          a.click()
          a.href = ""
        })
    } catch (error) {}
  }
  // return 404 if not found
  return (
    <>
      <div className="index">
        <div className="index-header">
          <p className="your-links-header mb-3">{t("favorite.title")}</p>
          {/* <LinkButton
            type=""
            buttontext={t("messages.export")}
            exportIcon="true"
            onClick={() => exportCSV()}
          /> */}
        </div>
        <div className="index-content">
          <table className="table">
            <thead>
              <tr>
                <th>{t("favorite.username")}</th>

                <th>{t("favorite.show")}</th>
                <th>{t("favorite.delete")}</th>
              </tr>
            </thead>
            <tbody>
              {favorite.map((user) => (
                <tr>
                  <td>{user.username}</td>
                  <td>
                    <a
                      href={user.url}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      <VisibilityIcon />
                    </a>
                  </td>
                  <td>
                    <div className="link-action fav">
                      <Deleteicon
                        item={user}
                        config={config}
                        onSaveData={() => handleEditData()}
                        api="user/favorite"
                        t={t}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default Favorite
