import React from "react";

const PaymentIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.92323 1.8042L16.5069 3.86102C16.6905 3.91835 16.851 4.03286 16.9649 4.18785C17.0788 4.34284 17.1402 4.53019 17.1402 4.72255V6.31478H18.9444C19.1836 6.31478 19.4131 6.40983 19.5823 6.57901C19.7515 6.74818 19.8465 6.97764 19.8465 7.2169V14.4338C19.8465 14.6731 19.7515 14.9025 19.5823 15.0717C19.4131 15.2409 19.1836 15.3359 18.9444 15.3359L16.0396 15.3368C15.6905 15.7969 15.2665 16.2029 14.7766 16.5367L9.92323 19.8465L5.06984 16.5376C4.3414 16.0409 3.74531 15.3738 3.3334 14.5943C2.92148 13.8148 2.70621 12.9465 2.7063 12.0649V4.72255C2.70641 4.53034 2.7679 4.3432 2.88182 4.18839C2.99573 4.03358 3.15611 3.9192 3.33958 3.86193L9.92323 1.8042ZM9.92323 3.69323L4.51053 5.3856V12.0649C4.5104 12.6172 4.63705 13.1621 4.88073 13.6578C5.1244 14.1534 5.4786 14.5865 5.91603 14.9237L6.08653 15.0464L9.92323 17.6634L13.335 15.3359H9.02111C8.78186 15.3359 8.5524 15.2409 8.38322 15.0717C8.21404 14.9025 8.119 14.6731 8.119 14.4338V7.2169C8.119 6.97764 8.21404 6.74818 8.38322 6.57901C8.5524 6.40983 8.78186 6.31478 9.02111 6.31478H15.3359V5.3856L9.92323 3.69323ZM9.92323 10.8254V13.5317H18.0423V10.8254H9.92323ZM9.92323 9.02113H18.0423V8.11901H9.92323V9.02113Z"
        fill="white"
      />
    </svg>
  );
};
export default PaymentIcon;
