import React from "react"

const PlusIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8.72727 7.27273H11.6364V8.72727H8.72727V11.6364H7.27273V8.72727H4.36364V7.27273H7.27273V4.36364H8.72727V7.27273Z"
        fill="#2975CF"
      />
    </svg>
  )
}

export default PlusIcon
