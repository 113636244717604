import React from "react";

const LocationIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 14.8041L12.0063 11.2978C12.6996 10.6044 13.1718 9.72087 13.3631 8.75906C13.5544 7.79726 13.4562 6.80033 13.0809 5.89434C12.7056 4.98835 12.07 4.214 11.2547 3.66919C10.4393 3.12438 9.48065 2.83359 8.5 2.83359C7.51936 2.83359 6.56074 3.12438 5.74535 3.66919C4.92997 4.214 4.29445 4.98835 3.91915 5.89434C3.54385 6.80033 3.44563 7.79726 3.63691 8.75906C3.82818 9.72087 4.30037 10.6044 4.99375 11.2978L8.5 14.8041ZM8.5 16.8072L3.99217 12.2994C3.10062 11.4078 2.49347 10.2719 2.24749 9.0353C2.00152 7.79867 2.12777 6.51687 2.61028 5.352C3.09279 4.18713 3.90989 3.19149 4.95825 2.491C6.00661 1.79051 7.23915 1.41663 8.5 1.41663C9.76085 1.41663 10.9934 1.79051 12.0418 2.491C13.0901 3.19149 13.9072 4.18713 14.3897 5.352C14.8722 6.51687 14.9985 7.79867 14.7525 9.0353C14.5065 10.2719 13.8994 11.4078 13.0078 12.2994L8.5 16.8072ZM8.5 9.20824C8.87572 9.20824 9.23606 9.05898 9.50174 8.7933C9.76741 8.52763 9.91667 8.16729 9.91667 7.79157C9.91667 7.41585 9.76741 7.05551 9.50174 6.78983C9.23606 6.52416 8.87572 6.3749 8.5 6.3749C8.12428 6.3749 7.76394 6.52416 7.49827 6.78983C7.23259 7.05551 7.08333 7.41585 7.08333 7.79157C7.08333 8.16729 7.23259 8.52763 7.49827 8.7933C7.76394 9.05898 8.12428 9.20824 8.5 9.20824ZM8.5 10.6249C7.74855 10.6249 7.02789 10.3264 6.49653 9.79504C5.96518 9.26369 5.66667 8.54302 5.66667 7.79157C5.66667 7.04012 5.96518 6.31945 6.49653 5.7881C7.02789 5.25675 7.74855 4.95824 8.5 4.95824C9.25145 4.95824 9.97212 5.25675 10.5035 5.7881C11.0348 6.31945 11.3333 7.04012 11.3333 7.79157C11.3333 8.54302 11.0348 9.26369 10.5035 9.79504C9.97212 10.3264 9.25145 10.6249 8.5 10.6249Z"
        fill="#163152"
      />
    </svg>
  );
};
export default LocationIcon;
