import React from "react";

const QuestionIcon = () => {
  return (
    <svg viewBox="0 0 512 512">
      <path
        d="M411.272,52.471c-44.929-34.326-98.621-52.47-155.273-52.47c-68.38,0-132.667,26.629-181.019,74.98
			C26.629,123.334,0,187.621,0,256.001c0,68.463,26.686,132.807,75.143,181.178c1.626,1.622,3.755,2.434,5.885,2.434
			c2.133,0,4.268-0.815,5.895-2.444c3.249-3.256,3.245-8.53-0.01-11.779c-45.305-45.224-70.254-105.38-70.254-169.388
			c0-63.931,24.896-124.035,70.102-169.241c45.206-45.205,105.31-70.101,169.24-70.101c52.964,0,103.16,16.961,145.158,49.049
			c3.657,2.793,8.883,2.093,11.676-1.561C415.626,60.49,414.927,55.264,411.272,52.471z"
      />

      <path
        d="M437.234,75.199c-3.248-3.254-8.522-3.263-11.779-0.012c-3.257,3.248-3.263,8.522-0.014,11.778
			c45.076,45.185,69.901,105.216,69.901,169.035c0,63.931-24.896,124.035-70.102,169.24c-45.206,45.206-105.31,70.102-169.24,70.102
			c-52.784,0-102.841-16.856-144.76-48.744c-3.66-2.785-8.886-2.074-11.672,1.586c-2.785,3.661-2.074,8.886,1.586,11.672
			c44.842,34.113,98.387,52.144,154.846,52.144c68.38,0,132.667-26.629,181.019-74.98C485.371,388.666,512,324.379,512,255.999
			C512,187.738,485.447,123.528,437.234,75.199z"
      />

      <path
        d="M262.474,352.114c-16.457,0-30.361,13.904-30.361,30.361v8.094c0,16.741,13.62,30.361,30.361,30.361
			c17.309,0,30.361-13.052,30.361-30.361v-8.094C292.833,365.451,279.498,352.114,262.474,352.114z M276.175,390.568
			c0,7.939-5.763,13.703-13.703,13.703c-7.556,0-13.703-6.147-13.703-13.703v-8.094c0-7.299,6.403-13.703,13.703-13.703
			c7.811,0,13.703,5.891,13.703,13.703V390.568z"
      />

      <path
        d="M365.673,192.283c0-56.657-46.296-102.75-103.2-102.75c-36.409,0-70.353,19.506-88.577,50.893
			c-8.459,14.501-3.842,32.702,10.508,41.437c0.044,0.027,0.089,0.053,0.134,0.08c4.746,2.768,10.114,4.232,15.521,4.232
			c10.962-0.001,20.848-5.729,26.468-15.364c7.301-12.679,21.074-20.556,35.947-20.556c24.073,0,42.928,18.461,42.928,42.029
			c0,23.176-19.258,42.029-42.928,42.029c-16.174,0-30.361,14.187-30.361,30.361c0,0.328,0.014,0.642,0.04,0.944
			c-0.028,0.302-0.04,0.589-0.04,0.855v46.761c0,16.741,13.619,30.361,30.361,30.361c17.309,0,30.361-13.052,30.361-30.361v-22.852
			h-0.001C336.043,277.245,365.673,237.806,365.673,192.283z M282.588,275.901c-3.758,0.888-6.411,4.243-6.411,8.105v29.226
			c0,7.939-5.763,13.703-13.703,13.703c-7.556,0-13.703-6.147-13.703-13.703v-44.853c0.345-1.038,0.45-2.011,0.45-2.807
			c0-0.817-0.12-1.622-0.351-2.392c0.85-6.463,7.061-12.21,13.604-12.21c32.856,0,59.586-26.327,59.586-58.687
			c0-15.908-6.222-30.739-17.519-41.762c-11.184-10.914-26.124-16.924-42.068-16.924c-20.812,0-40.118,11.075-50.359,28.861
			c-2.578,4.419-7.085,7.057-12.057,7.058c-2.435,0-4.874-0.665-7.059-1.923c-6.577-4.048-8.604-12.105-4.705-18.788
			c15.262-26.285,43.686-42.614,74.18-42.614c47.719,0,86.542,38.621,86.542,86.092
			C349.015,232.264,321.699,266.649,282.588,275.901z"
      />
    </svg>
  );
};

export default QuestionIcon;
