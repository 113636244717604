import React from "react"

const MessageIcon = () => {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3.78571L8.8906 8.67037C9.5624 9.08625 10.4376 9.08625 11.1094 8.67037L19 3.78571M3 14H17C18.1046 14 19 13.1685 19 12.1429V2.85714C19 1.83147 18.1046 1 17 1H3C1.89543 1 1 1.83147 1 2.85714V12.1429C1 13.1685 1.89543 14 3 14Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default MessageIcon
