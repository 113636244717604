import React from 'react'

const PageNotFound = () => {
    return (
        <div id="wrapper" style={{textAlign:"center"}}>
            <img src="https://i.imgur.com/qIufhof.png" />
            <div id="info">
                <h3>عذرا هذه الصفحة غير موجودة </h3>
                <a href={"/links"}>الرجوع الى الرئيسية</a>
            </div>
        </div >
    )
}

export default PageNotFound