import React from "react";

const NewsLetter = () => {
  return (
    <svg
      width="25"
      height="25"
      fill="none"
      strokeWidth={8}
      viewBox="0 0 220.001 220.001"
    >
      <path
        d="M136.06,52.01c-0.02,0-0.039-0.009-0.06-0.009h-0.031H12.031H12c-0.021,0-0.04,0.009-0.06,0.01
				C5.35,52.061,0,57.417,0,64.005v75.992c0,6.619,5.398,12.004,12.031,12.004h123.938c6.633,0,12.031-5.385,12.031-12.004V64.005
				C148,57.417,142.65,52.061,136.06,52.01z M125.606,60.001L74,106.61L22.394,60.001H125.606z M8,137.939V64.005
				c0-1.756,1.161-3.207,2.748-3.746l37.604,33.966L8,137.939z M13.288,144.001l40.999-44.416L71.32,114.97
				c0.762,0.687,1.719,1.031,2.68,1.031s1.918-0.344,2.68-1.031l17.033-15.385l40.999,44.416H13.288z M140,137.939L99.648,94.225
				l37.604-33.966c1.586,0.539,2.748,1.99,2.748,3.746V137.939z"
      />
      <path
        d="M196.554,88.858c1.896,1.728,4.84,1.575,6.546-0.343l15.729-17.686c1.562-1.562,1.562-4.094,0-5.656
				c-1.562-1.563-4.094-1.562-5.656,0L204,75.498V28.001h-8v47.497l-9.172-10.325c-1.562-1.562-4.094-1.562-5.656,0
				c-1.563,1.562-1.562,8.094,0,9.656L196.554,88.858z"
      />
      <path
        d="M213.172,167.113L204,177.439v-47.498h-8v47.497l-9.172-10.325c-1.562-1.562-4.094-1.562-5.656,0s-1.562,8.094,0,9.656
				l15.382,14.028c1.896,1.73,4.84,1.576,6.546-0.342l15.729-17.686c1.562-1.562,1.562-4.094,0-5.656
				C217.267,165.551,214.734,165.551,213.172,167.113z"
      />
    </svg>
  );
};

export default NewsLetter;
