import React from "react";

const UploadFileIcon = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.2212 22.8598L25.0413 20.6799L27.2212 18.5C27.7938 17.9273 28.2481 17.2475 28.558 16.4993C28.8679 15.7511 29.0274 14.9492 29.0274 14.1394C29.0274 13.3295 28.8679 12.5276 28.558 11.7794C28.2481 11.0313 27.7938 10.3514 27.2212 9.77878C26.6486 9.20614 25.9687 8.7519 25.2205 8.44198C24.4723 8.13207 23.6704 7.97256 22.8606 7.97256C22.0508 7.97256 21.2488 8.13207 20.5007 8.44198C19.7525 8.7519 19.0726 9.20614 18.5 9.77878L16.3201 11.9587L14.1402 9.77878L16.3201 7.59887C18.0596 5.88749 20.4048 4.93279 22.845 4.94273C25.2852 4.95267 27.6226 5.92643 29.3481 7.65192C31.0736 9.3774 32.0473 11.7148 32.0573 14.155C32.0672 16.5952 31.1125 18.9404 29.4011 20.6799L27.2212 22.8598V22.8598ZM22.8598 27.2212L20.6799 29.4011C19.8235 30.2715 18.8033 30.9638 17.6781 31.438C16.5528 31.9121 15.3448 32.1588 14.1238 32.1638C12.9027 32.1688 11.6928 31.9319 10.5637 31.4669C9.43464 31.002 8.40881 30.318 7.54538 29.4546C6.68196 28.5912 5.99803 27.5653 5.53305 26.4363C5.06806 25.3072 4.83123 24.0973 4.8362 22.8762C4.84117 21.6551 5.08785 20.4472 5.56202 19.3219C6.03618 18.1967 6.72844 17.1764 7.59887 16.3201L9.77878 14.1402L11.9587 16.3201L9.77878 18.5C9.20614 19.0726 8.7519 19.7525 8.44198 20.5007C8.13207 21.2488 7.97256 22.0508 7.97256 22.8606C7.97256 23.6704 8.13207 24.4723 8.44198 25.2205C8.7519 25.9687 9.20614 26.6486 9.77878 27.2212C10.3514 27.7938 11.0313 28.2481 11.7794 28.558C12.5276 28.8679 13.3295 29.0274 14.1394 29.0274C14.9492 29.0274 15.7511 28.8679 16.4993 28.558C17.2475 28.2481 17.9273 27.7938 18.5 27.2212L20.6799 25.0413L22.8598 27.2212ZM22.8598 11.9587L25.0413 14.1402L14.1402 25.0397L11.9587 22.8598L22.8598 11.9602V11.9587ZM8.90312 3.53503L11.8816 2.73645L13.4772 8.69499L10.5003 9.49357L8.90312 3.53658V3.53503ZM23.5227 28.3065L26.4997 27.508L28.0969 33.465L25.1184 34.2635L23.5227 28.3065V28.3065ZM3.53503 8.90312L9.49203 10.5003L8.69345 13.4772L2.73645 11.8816L3.53503 8.90312ZM28.3065 23.5227L34.2635 25.1184L33.465 28.0969L27.508 26.4997L28.3065 23.5227V23.5227Z"
        fill="#D8D8D8"
      />
    </svg>
  );
};
export default UploadFileIcon;
