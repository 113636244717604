import React from "react";

const ImagesIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.95833 8.7875L5.54167 7.20417L9.89583 11.5583L12.6667 8.7875L15.0417 11.1625V3.95833H3.95833V8.7875ZM3.95833 11.0271V15.0417H6.4125L8.77642 12.6785L5.54167 9.44379L3.95833 11.0271ZM8.65212 15.0417H15.0417V13.4021L12.6667 11.0271L8.65212 15.0417ZM3.16667 2.375H15.8333C16.0433 2.375 16.2447 2.45841 16.3931 2.60687C16.5416 2.75534 16.625 2.9567 16.625 3.16667V15.8333C16.625 16.0433 16.5416 16.2447 16.3931 16.3931C16.2447 16.5416 16.0433 16.625 15.8333 16.625H3.16667C2.9567 16.625 2.75534 16.5416 2.60687 16.3931C2.45841 16.2447 2.375 16.0433 2.375 15.8333V3.16667C2.375 2.9567 2.45841 2.75534 2.60687 2.60687C2.75534 2.45841 2.9567 2.375 3.16667 2.375ZM12.2708 7.91667C11.9559 7.91667 11.6538 7.79155 11.4311 7.56886C11.2084 7.34616 11.0833 7.04411 11.0833 6.72917C11.0833 6.41422 11.2084 6.11218 11.4311 5.88948C11.6538 5.66678 11.9559 5.54167 12.2708 5.54167C12.5858 5.54167 12.8878 5.66678 13.1105 5.88948C13.3332 6.11218 13.4583 6.41422 13.4583 6.72917C13.4583 7.04411 13.3332 7.34616 13.1105 7.56886C12.8878 7.79155 12.5858 7.91667 12.2708 7.91667Z"
        fill="#163152"
      />
    </svg>
  );
};
export default ImagesIcon;
