import axios from "axios";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const config = JSON.parse(localStorage.getItem("headers"));

const FavoriteReception = (props) => {
  const { t } = props;

  const history = useHistory();

  const setUser = async () => {
    try {
      axios
        .post(
          "https://swipyy.com/api/user/favorite",
          { user_id: props.match.params.id },
          config
        )
        .then((res) => {
          history.push("/favorite");
        });
    } catch (error) {}
  };
  useEffect(() => {
    setUser();
  }, []);
  return (
    <div>
      {" "}
      <h1 className="text-center pt-5">{t("favorite.loading")}</h1>
    </div>
  );
};

export default FavoriteReception;
