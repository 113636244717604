import React from "react";

const LinksIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50004 15.5833C12.4122 15.5833 15.5834 12.4121 15.5834 8.49996C15.5834 4.58784 12.4122 1.41663 8.50004 1.41663C7.40024 1.4158 6.3154 1.67147 5.33171 2.16331C4.34802 2.65516 3.49259 3.36963 2.83337 4.24996H4.75296C5.57092 3.52871 6.57962 3.0588 7.65803 2.89662C8.73644 2.73444 9.83874 2.88688 10.8327 3.33565C11.8266 3.78442 12.6699 4.51046 13.2614 5.42664C13.8529 6.34281 14.1675 7.41021 14.1674 8.50075C14.1673 9.59128 13.8526 10.6586 13.2609 11.5747C12.6692 12.4908 11.8258 13.2167 10.8318 13.6653C9.83781 14.1139 8.73548 14.2661 7.6571 14.1038C6.57872 13.9414 5.57009 13.4714 4.75225 12.75H2.83266C3.49195 13.6304 4.3475 14.3449 5.33132 14.8368C6.31514 15.3286 7.40012 15.5842 8.50004 15.5833ZM3.54171 11.3333V9.2083H9.20837V7.79163H3.54171V5.66663L3.8147e-05 8.49996L3.54171 11.3333Z"
        fill="#D8D8D8"
      />
    </svg>
  );
};
export default LinksIcon;
