import React, { useState, useEffect } from "react"
import Rocket from "../assets/images/Rocket"
import Swal from "sweetalert2"
import axios from "axios"
import cookies from "js-cookie"

const config = JSON.parse(localStorage.getItem("headers"))

const Payments = (props) => {
  const { t } = props

  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [allPackages, setAllPackages] = useState()
  const [packageDetails, setPackageDetails] = useState(null)

  // active plan
  const [currentPlan, setCurrentPlan] = useState({ type: "plan-yearly" })
  const [showMore, setShowMore] = useState(9)
  const [freeShoeMore, setFreeShoeMore] = useState(9)
  const activePlanStyle = {
    backgroundColor: "white",
    color: "#8055f0",
    fontWeight: "bold",
  }

  config.headers["Accept-Language"] = cookies.get("i18next") || "en"

  function openModal() {
    setIsOpen(true)
  }

  const getAllPackages = async () => {
    try {
      axios.get("https://swipyy.com/api/user/package/", config).then((res) => {
        setAllPackages(res.data.data.data)
        setPackageDetails(res.data.data.membership)
        const plan = res.data.data.data.filter((a) => a.type == "yearly")[0]
        setCurrentPlan((prev) => ({
          ...prev,
          plan: plan,
        }))
      })
    } catch (error) {}
  }
  const submitPay = async (packageId) => {
    // await window.open('https://swipyy.store/%D8%A8%D8%A7%D9%82%D8%A9-pro/p489955748', "_blank").focus();

    try {
      axios
        .post(
          "https://swipyy.com/api/user/myfatoorah/payment/" + packageId,
          {},
          config
        )
        .then((res) => {
          window.location.replace(res.data.data.url)
        })
    } catch (error) {}
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false)
  }
  function sucesesEdit() {
    Swal.fire(
      t("edit-success.good-job"),
      t("edit-success.payment-success"),
      t("edit-success.success")
    )
    setIsOpen(false)
  }
  useEffect(() => {
    props.onFinishRequest(true)

    getAllPackages()
  }, [])
  return (
    <div className="payments">
      {/* <ImgCrop t={t} /> */}

      {/* custom payments paln */}
      <div className="plans row">
        {/* free plan */}
        <div className="free-plan order-2 order-md-0 col-md-6 col-12 mt-md-0 mt-5">
          <p className="fw-bold text-uppercase text-purple  fs-16px">
            {t("payments.starter")}
          </p>
          <h3 className="mt-2 fs-4 fw-bold">
            {t("payments.free")}{" "}
            <span className="fs-6 fw-normal">/ {t("payments.forever")}</span>
          </h3>
          <div className="details mt-4">
            <ul>
              {allPackages ? (
                <>
                  {allPackages
                    .filter((a) => a.type == "free")[0]
                    .dtails.map((item, index) => (
                      <>
                        {index > freeShoeMore ? (
                          <></>
                        ) : (
                          <li key={index} className="mt-2">
                            <img
                              className=""
                              src={item.icon}
                              width={24}
                              height={24}
                            />
                            <span className="fs-6 text-secondary p-2">
                              {item.text}
                            </span>
                          </li>
                        )}
                      </>
                    ))}
                </>
              ) : (
                <></>
              )}
              <button
                className={`btn btn-ouline btn-sm fs-16px text-purple mt-2 ${
                  freeShoeMore == 100 ? "d-none" : ""
                }`}
                onClick={() => {
                  setFreeShoeMore(100)
                }}
              >
                {t("payments.show_more")}
              </button>
            </ul>
          </div>
        </div>
        <div className="w-100 p-1 bg-gray my-5 order-1 rounded-pill d-md-none"></div>
        {/* pro plans */}
        <div className="paid-plan col-md-6 col-12">
          {/* plan */}
          <div className="">
            <p className="fw-bold text-uppercase text-purple fs-16px">
              {t("payments.pro")}
            </p>
            {/* toggle plan */}
            <div
              className="plans py-1 px-2 rounded-pill bg-gray d-flex col-sm-12 col-lg-6 mt-2"
              onClick={(e) => {
                let plan
                if (e.target.id.startsWith("plan")) {
                  switch (e.target.id) {
                    case "plan-yearly":
                      plan = allPackages.filter((a) => a.type == "yearly")[0]
                      break
                    case "plan-half":
                      plan = allPackages.filter((a) => a.type == "half")[0]
                      break
                    case "plan-monthly":
                      plan = allPackages.filter((a) => a.type == "monthly")[0]
                      break
                  }
                  setCurrentPlan((prev) => ({
                    ...prev,
                    type: e.target.id,
                    plan: plan,
                  }))
                  e.target.backgroundColor = "white"
                }
              }}
            >
              <div
                id="plan-yearly"
                style={
                  currentPlan.type === "plan-yearly"
                    ? { ...activePlanStyle }
                    : {}
                }
                className="plan px-2 py-1 fs-12px text-dark-gray rounded-pill flex-fill text-center cursor-pointer"
              >
                {t("payments.yearly")}
              </div>
              <div
                id="plan-half"
                style={
                  currentPlan.type === "plan-half" ? { ...activePlanStyle } : {}
                }
                className="plan px-2 py-1 fs-12px text-dark-gray rounded-pill flex-fill text-center cursor-pointer"
              >
                {t("payments.6-months")}
              </div>
              <div
                id="plan-monthly"
                style={
                  currentPlan.type === "plan-monthly"
                    ? { ...activePlanStyle }
                    : {}
                }
                className="plan px-2 py-1 fs-12px text-dark-gray rounded-pill flex-fill text-center cursor-pointer"
              >
                {t("payments.monthly")}
              </div>
            </div>
          </div>
          <h3 className="fs-4 fw-bold mt-4">
            {currentPlan.plan ? currentPlan.plan.amount : ""}
            <span className="fs-6 fw-normal"> / {t("payments.yearly")}</span>
          </h3>
          <div className="details mt-4 row">
            <div className="col-12 col-md-6 order-1 order-md-3">
              <Rocket className="col-md-12 col-sm-8 col-12 rocket-img" />
            </div>
            <ul className="px-4 col-12 col-md-6 order-2">
              {currentPlan.plan ? (
                <>
                  {currentPlan.plan.dtails.map((item, index) => (
                    <>
                      {index > showMore ? (
                        <></>
                      ) : (
                        <li key={index} className="mt-2">
                          <img src={item.icon} width={24} height={24} />
                          <span className="ms-2 fs-6 text-secondary p-2">
                            {item.text}
                          </span>
                        </li>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
              <button
                className={`btn btn-ouline btn-sm fs-16px text-purple mt-2 ${
                  showMore == 100 ? "d-none" : ""
                }`}
                onClick={() => {
                  setShowMore(100)
                }}
              >
                {t("payments.show_more")}
              </button>
            </ul>
          </div>
          <button
            className="btn btn-lg bg-purple mt-4 text-white col-6 fs-4 py-3 py-md-1 fs-4 rounded-pill"
            onClick={() => {
              submitPay(currentPlan.plan.id)
            }}
          >
            {t("payments.update")}
          </button>
        </div>
      </div>
      {/* end of custom payments */}

      <div className="success-page mt-5">
        <div className="container pb-5">
          <div className="success-list ">
            <h2>{t("payments.membership_details")}</h2>
            <div className="mt-4 bg-light shadow-sm rounded p-3 p-sm-4 mb-4">
              <div className="row">
                <div className="col-sm text-muted font-weight-bold">
                  {t("payments.start_date")}
                </div>
                <div className="col-sm text-sm-right font-weight-600">
                  {packageDetails ? packageDetails.start_date : ""}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm text-muted font-weight-bold">
                  {t("payments.end_date")}
                </div>
                <div className="col-sm text-sm-right font-weight-600">
                  {packageDetails ? packageDetails.end_date : ""}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm text-muted font-weight-bold">
                  {t("payments.payment_method")}
                </div>
                <div className="col-sm text-sm-right font-weight-600">
                  Credit Card
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-sm text-muted font-weight-bold">
                  {t("payments.payment_amout")}
                </div>
                <div className="col-sm text-sm-right text-6 font-weight-500">
                  <span className="room-single-price">
                    {t("payments.sar")}{" "}
                    {packageDetails ? packageDetails.package_amount : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="text-center"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Payments
