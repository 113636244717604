import React from "react";

const LinksIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2474 2.7474C12.0773 1.91753 13.4227 1.91753 14.2526 2.7474C15.0825 3.57726 15.0825 4.92274 14.2526 5.7526L11.0651 8.9401C10.2352 9.76997 8.88975 9.76997 8.05989 8.9401C7.64496 8.52517 6.97222 8.52517 6.55729 8.9401C6.14235 9.35503 6.14235 10.0278 6.55729 10.4427C8.21701 12.1024 10.908 12.1024 12.5677 10.4427L15.7552 7.2552C17.4149 5.59547 17.4149 2.90452 15.7552 1.2448C14.0955 -0.414932 11.4045 -0.414932 9.74479 1.2448L8.15104 2.83855C7.7361 3.25348 7.7361 3.92622 8.15104 4.34115C8.56597 4.75608 9.23871 4.75608 9.65364 4.34115L11.2474 2.7474Z"
        fill="white"
      />
      <path
        d="M5.9349 8.0599C6.76477 7.23003 8.11025 7.23003 8.94011 8.0599C9.35504 8.47483 10.0278 8.47483 10.4427 8.0599C10.8576 7.64497 10.8576 6.97223 10.4427 6.5573C8.78298 4.89757 6.09203 4.89757 4.4323 6.5573L1.2448 9.7448C-0.414932 11.4045 -0.414932 14.0955 1.2448 15.7552C2.90453 17.4149 5.59548 17.4149 7.25521 15.7552L8.84896 14.1615C9.26389 13.7465 9.26389 13.0738 8.84896 12.6589C8.43403 12.2439 7.76129 12.2439 7.34636 12.6589L5.75261 14.2526C4.92274 15.0825 3.57727 15.0825 2.7474 14.2526C1.91754 13.4227 1.91754 12.0773 2.7474 11.2474L5.9349 8.0599Z"
        fill="white"
      />
    </svg>
  );
};
export default LinksIcon;
