import React from "react";

const SliderBlue = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.54167 7.8625L4.95833 6.44583L8.85417 10.3417L11.3333 7.8625L13.4583 9.9875V3.54167H3.54167V7.8625ZM3.54167 9.86637V13.4583H5.7375L7.85258 11.344L4.95833 8.44971L3.54167 9.86637ZM7.74137 13.4583H13.4583V11.9914L11.3333 9.86637L7.74137 13.4583ZM2.83333 2.125H14.1667C14.3545 2.125 14.5347 2.19963 14.6675 2.33247C14.8004 2.4653 14.875 2.64547 14.875 2.83333V14.1667C14.875 14.3545 14.8004 14.5347 14.6675 14.6675C14.5347 14.8004 14.3545 14.875 14.1667 14.875H2.83333C2.64547 14.875 2.4653 14.8004 2.33247 14.6675C2.19963 14.5347 2.125 14.3545 2.125 14.1667V2.83333C2.125 2.64547 2.19963 2.4653 2.33247 2.33247C2.4653 2.19963 2.64547 2.125 2.83333 2.125ZM10.9792 7.08333C10.6974 7.08333 10.4271 6.97139 10.2279 6.77213C10.0286 6.57288 9.91667 6.30263 9.91667 6.02083C9.91667 5.73904 10.0286 5.46879 10.2279 5.26953C10.4271 5.07027 10.6974 4.95833 10.9792 4.95833C11.261 4.95833 11.5312 5.07027 11.7305 5.26953C11.9297 5.46879 12.0417 5.73904 12.0417 6.02083C12.0417 6.30263 11.9297 6.57288 11.7305 6.77213C11.5312 6.97139 11.261 7.08333 10.9792 7.08333Z"
        fill="white"
      />
    </svg>
  );
};
export default SliderBlue;
