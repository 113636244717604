import React, { useEffect, useRef, useState } from "react"

import { Tabs, Tab } from "react-bootstrap"
import Link from "../component/links/Link"
import Social from "../component/links/Social"
import Location from "../component/links/Location"
import Images from "../component/links/Images"
import Banks from "../component/links/Banks"
import Menu from "../component/links/Menu"

// import LinkIcon from "@mui/cons-material/Link";

import AttachFileIcon from "@mui/icons-material/AttachFile"
import Pdf from "../component/links/Pdf"
import LinkIcon from "../component/icons/LinkIcon"
import SocialIcon from "../component/icons/SocialIcon"
import LocationIcon from "../component/icons/LocationIcon"
import ImagesIcon from "../component/icons/ImagesIcon"
import BankIcon from "../component/icons/BankIcon"
import MenuIcon from "../component/icons/MenuIcon"
import axios from "axios"
import NewBtn from "../component/NewBtn"
import "intro.js/introjs.css"
import { Steps } from "intro.js-react"
import { useHistory } from "react-router-dom"

const config = JSON.parse(localStorage.getItem("headers"))

const Links = (props) => {
  const navigate = useHistory()
  const { t } = props

  const [currentStep, setCurrentStep] = useState(0)
  const [enabled, setEnabled] = useState(false)
  const steps = [
    {
      element: ".intro-step-one",
      title: t("intro.links.step-one-title"),
      intro: "",
    },
    {
      element: ".intro-step-two",
      title: t("intro.links.step-two-title"),
      intro: t("intro.links.step-two-body"),
      position: "left",
    },
    {
      element: ".intro-step-three",
      title: t("intro.links.step-three-title"),
      intro: t("intro.links.step-three-body"),
    },
    {
      element: ".intro-step-four",
      title: t("intro.links.step-four-title"),
      intro: t("intro.links.step-four-body"),
    },
    {
      element: ".intro-step-five",
      title: t("intro.links.step-five-title"),
      intro: t("intro.links.step-five-body"),
    },
    {
      element: ".intro-step-six",
      title: t("intro.links.step-six-title"),
      intro: t("intro.links.step-six-body"),
    },
  ]
  // Show intro js - Once
  useEffect(() => {
    const introLinks = localStorage.getItem("intro-links") || false
    if (!introLinks) {
      setEnabled(true)
    }
  }, [])

  const [nav, setNav] = useState({})

  const handleEditData = (key, e) => {
    props.onSaveData()
  }

  const startRequest = (key, e) => {
    props.onStartRequest(true)
  }
  const finishRequest = (key, e) => {
    props.onFinishRequest(false)
  }
  const getMenu = async () => {
    try {
      await axios
        .get("https://swipyy.com/api/user/home", config)
        .then((res) => {
          setNav(res.data.data[0])
        })
    } catch (error) {}
  }
  useEffect(() => {
    getMenu()
  }, [])

  const [currentTab, setCurrentTab] = useState("social")

  return (
    <div className="links-page">
      <div className="links-page-nav">
        <Tabs
          activeKey={currentStep >= 4 ? "link" : currentTab}
          onSelect={(tab) => {
            setCurrentTab(tab)
          }}
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab
            className="test-find"
            eventKey="social"
            title={
              <div className="align-pro intro-step-one">
                <div className="link-svg">
                  <SocialIcon />
                </div>

                <div>
                  <strong>{t("links.header.social")}</strong>
                </div>
              </div>
            }
          >
            <Social
              onStartRequest={() => startRequest()}
              onFinishRequest={() => finishRequest()}
              t={t}
            />
          </Tab>
          <Tab
            eventKey="link"
            title={
              <div className="align-pro intro-step-five">
                <div className="link-svg">
                  <LinkIcon />
                </div>

                <div>
                  <strong>{t("links.header.links")}</strong>
                </div>
                {nav.links == 1 ? <NewBtn /> : null}
              </div>
            }
          >
            <Link
              onStartRequest={() => startRequest()}
              onFinishRequest={() => finishRequest()}
              t={t}
            />
          </Tab>
          <Tab
            eventKey="location"
            title={
              <div className="align-pro">
                <div className="link-svg">
                  <LocationIcon />
                </div>

                <div>
                  <strong>{t("links.header.location")}</strong>
                </div>
                {nav.locations == 1 ? <NewBtn /> : null}
              </div>
            }
          >
            <Location
              onStartRequest={() => startRequest()}
              onFinishRequest={() => finishRequest()}
              t={t}
            />
          </Tab>
          <Tab
            eventKey="images"
            title={
              <div className="align-pro">
                <div className="link-svg">
                  <ImagesIcon />
                </div>

                <div>
                  <strong>{t("links.header.slider")}</strong>
                </div>
                {nav.images == 1 ? <NewBtn /> : null}
              </div>
            }
          >
            <Images
              onStartRequest={() => startRequest()}
              onFinishRequest={() => finishRequest()}
              t={t}
            />
          </Tab>
          <Tab
            eventKey="menu"
            title={
              <div className="align-pro">
                <div className="link-svg">
                  <MenuIcon />
                </div>

                <div>
                  <strong>{t("links.header.menu")}</strong>
                </div>
                {nav.menues == 1 ? <NewBtn /> : null}
              </div>
            }
          >
            <Menu
              onStartRequest={() => startRequest()}
              onFinishRequest={() => finishRequest()}
              t={t}
            />
          </Tab>
          <Tab
            eventKey="pdf"
            title={
              <div className="align-pro">
                <div className="link-svg">
                  <AttachFileIcon />
                </div>

                <div>
                  <strong>PDF</strong>
                </div>
                {nav.pdf == 1 ? <NewBtn /> : null}
              </div>
            }
          >
            <Pdf
              onStartRequest={() => startRequest()}
              onFinishRequest={() => finishRequest()}
              t={t}
            />
          </Tab>
          <Tab
            eventKey="banks"
            title={
              <div className="align-pro">
                <div className="link-svg">
                  <BankIcon />
                </div>

                <div>
                  <strong>{t("links.header.banks")}</strong>
                </div>
                {nav.bank == 1 ? <NewBtn /> : null}
              </div>
            }
          >
            <Banks
              onStartRequest={() => startRequest()}
              onFinishRequest={() => finishRequest()}
              t={t}
            />
          </Tab>
        </Tabs>
      </div>
      {enabled && (
        <Steps
          enabled={enabled}
          steps={steps}
          onExit={() => {
            setEnabled(false)
            navigate.push("/appearance")
          }}
          initialStep={0}
          options={{
            exitOnOverlayClick: false,
            nextLabel: t("intro.next"),
            prevLabel: t("intro.prev"),
            doneLabel: t("intro.done"),
          }}
          onChange={(step) => {
            setCurrentStep(step)
            // if (step === steps.length - 1) {
            //   navigate.push("/appearance")
            // }
          }}
        />
      )}
    </div>
  )
}
export default Links
