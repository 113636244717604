import React from "react";

const TrashIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1437 4.28725H15.6833V5.7031H14.2675V14.9061C14.2675 15.0938 14.1929 15.2739 14.0601 15.4067C13.9274 15.5394 13.7473 15.614 13.5596 15.614H3.64865C3.4609 15.614 3.28084 15.5394 3.14808 15.4067C3.01532 15.2739 2.94073 15.0938 2.94073 14.9061V5.7031H1.52489V4.28725H5.0645V2.16349C5.0645 1.97574 5.13908 1.79567 5.27184 1.66291C5.4046 1.53015 5.58467 1.45557 5.77242 1.45557H11.4358C11.6235 1.45557 11.8036 1.53015 11.9364 1.66291C12.0691 1.79567 12.1437 1.97574 12.1437 2.16349V4.28725ZM12.8516 5.7031H4.35657V14.1982H12.8516V5.7031ZM6.48034 7.82686H7.89618V12.0744H6.48034V7.82686ZM9.31203 7.82686H10.7279V12.0744H9.31203V7.82686ZM6.48034 2.87141V4.28725H10.7279V2.87141H6.48034Z"
        fill="#163152"
      />
    </svg>
  );
};
export default TrashIcon;
