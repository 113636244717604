import React from "react";

const SocialIcon = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1797 11.1047H14.3335L14.2053 11.6175H13.1797V13.9252H12.6669V11.6175H11.6412V11.1047H12.6669V10.6247C12.6669 10.1675 12.7146 10.0016 12.8038 9.83443C12.8912 9.66925 13.0263 9.53418 13.1915 9.44674C13.3587 9.35751 13.5246 9.30981 13.9817 9.30981C14.1156 9.30981 14.233 9.32263 14.3335 9.34828V9.82263H13.9817C13.6423 9.82263 13.5389 9.84263 13.4335 9.89905C13.3556 9.94058 13.2976 9.99853 13.2561 10.0765C13.1997 10.1819 13.1797 10.2852 13.1797 10.6247V11.1047Z"
        fill="#163152"
      />
      <path
        d="M5.15419 2.65378C4.95931 2.65374 4.77221 2.73022 4.63314 2.86674C4.49407 3.00327 4.41416 3.18893 4.4106 3.38378L4.40342 3.78762C4.403 3.8093 4.398 3.83065 4.38875 3.85027C4.3795 3.86988 4.3662 3.88732 4.34974 3.90144C4.33328 3.91555 4.31401 3.92603 4.29322 3.93218C4.27242 3.93832 4.25056 3.94 4.22906 3.93711L3.82881 3.88275C3.30214 3.81095 2.79752 3.56839 2.31342 3.16506C2.16009 4.01378 2.45958 4.60172 3.18086 5.05531L3.62881 5.33685C3.65009 5.35023 3.66777 5.36861 3.68031 5.3904C3.69284 5.41219 3.69984 5.43672 3.7007 5.46185C3.70156 5.48697 3.69625 5.51192 3.68524 5.53452C3.67423 5.55711 3.65784 5.57667 3.63752 5.59147L3.22932 5.88967C3.47214 5.9048 3.70265 5.89403 3.89393 5.85608C5.10368 5.61454 5.90804 4.70429 5.90804 3.20275C5.90804 3.08019 5.64855 2.65378 5.15419 2.65378ZM3.89778 3.37429C3.90226 3.12712 3.97955 2.88677 4.11998 2.68332C4.2604 2.47987 4.45773 2.32237 4.68725 2.23054C4.91676 2.1387 5.16827 2.11662 5.41028 2.16704C5.65229 2.21747 5.87404 2.33817 6.04778 2.51403C6.23009 2.51275 6.38522 2.5589 6.73214 2.34865C6.64624 2.76916 6.60393 2.95172 6.42086 3.20275C6.42086 5.16224 5.2165 6.11506 3.99445 6.3589C3.1565 6.52608 1.93804 6.25147 1.58881 5.88685C1.76675 5.87301 2.48983 5.79531 2.90778 5.48942C2.55419 5.25634 1.14675 4.42813 2.07163 2.20147C2.50573 2.70839 2.94599 3.05352 3.39214 3.2366C3.68906 3.35839 3.76188 3.35583 3.89804 3.37454L3.89778 3.37429Z"
        fill="#163152"
      />
      <rect
        x="0.35"
        y="0.35"
        width="7.91539"
        height="7.91539"
        rx="1.65"
        stroke="#163152"
        strokeWidth="0.7"
      />
      <rect
        x="8.96554"
        y="7.73464"
        width="7.91539"
        height="7.91539"
        rx="1.65"
        stroke="#163152"
        strokeWidth="0.7"
      />
      <rect
        x="10.1963"
        y="0.35"
        width="5.45385"
        height="5.45385"
        rx="0.65"
        stroke="#163152"
        strokeWidth="0.7"
      />
      <rect
        x="1.58077"
        y="10.1961"
        width="5.45385"
        height="5.45385"
        rx="0.65"
        stroke="#163152"
        strokeWidth="0.7"
      />
    </svg>
  );
};
export default SocialIcon;
