import React from "react";

const LocationBlue = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13.933L11.3 10.633C11.9526 9.98029 12.397 9.14877 12.577 8.24354C12.7571 7.33831 12.6646 6.40002 12.3114 5.54733C11.9582 4.69463 11.36 3.96583 10.5926 3.45307C9.82519 2.94031 8.92296 2.66663 8 2.66663C7.07704 2.66663 6.17481 2.94031 5.40739 3.45307C4.63997 3.96583 4.04183 4.69463 3.68861 5.54733C3.33539 6.40002 3.24294 7.33831 3.42297 8.24354C3.603 9.14877 4.04741 9.98029 4.7 10.633L8 13.933ZM8 15.8183L3.75734 11.5756C2.91823 10.7365 2.34679 9.66741 2.11529 8.50352C1.88378 7.33964 2.0026 6.13324 2.45673 5.03689C2.91086 3.94054 3.6799 3.00347 4.66659 2.34418C5.65328 1.6849 6.81332 1.33301 8 1.33301C9.18669 1.33301 10.3467 1.6849 11.3334 2.34418C12.3201 3.00347 13.0891 3.94054 13.5433 5.03689C13.9974 6.13324 14.1162 7.33964 13.8847 8.50352C13.6532 9.66741 13.0818 10.7365 12.2427 11.5756L8 15.8183ZM8 8.66629C8.35362 8.66629 8.69276 8.52581 8.94281 8.27576C9.19286 8.02571 9.33333 7.68658 9.33333 7.33295C9.33333 6.97933 9.19286 6.64019 8.94281 6.39015C8.69276 6.1401 8.35362 5.99962 8 5.99962C7.64638 5.99962 7.30724 6.1401 7.05719 6.39015C6.80714 6.64019 6.66667 6.97933 6.66667 7.33295C6.66667 7.68658 6.80714 8.02571 7.05719 8.27576C7.30724 8.52581 7.64638 8.66629 8 8.66629ZM8 9.99962C7.29276 9.99962 6.61448 9.71867 6.11438 9.21857C5.61429 8.71848 5.33334 8.0402 5.33334 7.33295C5.33334 6.62571 5.61429 5.94743 6.11438 5.44734C6.61448 4.94724 7.29276 4.66629 8 4.66629C8.70725 4.66629 9.38552 4.94724 9.88562 5.44734C10.3857 5.94743 10.6667 6.62571 10.6667 7.33295C10.6667 8.0402 10.3857 8.71848 9.88562 9.21857C9.38552 9.71867 8.70725 9.99962 8 9.99962Z"
        fill="white"
      />
    </svg>
  );
};
export default LocationBlue;
