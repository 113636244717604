import React from "react"

const BankIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 2.88889C9.5 1.84568 10.3457 1 11.3889 1C12.4321 1 13.2778 1.84568 13.2778 2.88889V3.83333C13.2778 4.35494 13.7006 4.77778 14.2222 4.77778H17.0556C17.5772 4.77778 18 5.20062 18 5.72222V8.55556C18 9.07716 17.5772 9.5 17.0556 9.5H16.1111C15.0679 9.5 14.2222 10.3457 14.2222 11.3889C14.2222 12.4321 15.0679 13.2778 16.1111 13.2778H17.0556C17.5772 13.2778 18 13.7006 18 14.2222V17.0556C18 17.5772 17.5772 18 17.0556 18H14.2222C13.7006 18 13.2778 17.5772 13.2778 17.0556V16.1111C13.2778 15.0679 12.4321 14.2222 11.3889 14.2222C10.3457 14.2222 9.5 15.0679 9.5 16.1111V17.0556C9.5 17.5772 9.07716 18 8.55556 18H5.72222C5.20062 18 4.77778 17.5772 4.77778 17.0556V14.2222C4.77778 13.7006 4.35494 13.2778 3.83333 13.2778H2.88889C1.84568 13.2778 1 12.4321 1 11.3889C1 10.3457 1.84568 9.5 2.88889 9.5H3.83333C4.35494 9.5 4.77778 9.07716 4.77778 8.55556V5.72222C4.77778 5.20062 5.20062 4.77778 5.72222 4.77778H8.55556C9.07716 4.77778 9.5 4.35494 9.5 3.83333V2.88889Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default BankIcon
