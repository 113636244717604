import React from "react";

const LinkBlue = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5071 10.5027L11.5056 9.50114L12.5071 8.49955C12.7702 8.23645 12.979 7.9241 13.1213 7.58033C13.2637 7.23657 13.337 6.86812 13.337 6.49603C13.337 6.12395 13.2637 5.7555 13.1213 5.41174C12.979 5.06797 12.7702 4.75562 12.5071 4.49251C12.244 4.22941 11.9317 4.0207 11.5879 3.87831C11.2442 3.73592 10.8757 3.66263 10.5036 3.66263C10.1315 3.66263 9.76309 3.73592 9.41932 3.87831C9.07556 4.0207 8.76321 4.22941 8.5001 4.49251L7.49852 5.4941L6.49693 4.49251L7.49852 3.49093C8.29774 2.70462 9.37529 2.26598 10.4965 2.27054C11.6176 2.27511 12.6916 2.72251 13.4844 3.5153C14.2771 4.30809 14.7245 5.38204 14.7291 6.5032C14.7337 7.62437 14.295 8.70192 13.5087 9.50114L12.5071 10.5027ZM10.5033 12.5066L9.50168 13.5082C9.10822 13.9081 8.63947 14.2262 8.12246 14.444C7.60546 14.6619 7.05044 14.7752 6.48941 14.7775C5.92839 14.7798 5.37246 14.671 4.8537 14.4573C4.33494 14.2437 3.86361 13.9295 3.4669 13.5328C3.07019 13.136 2.75596 12.6647 2.54232 12.146C2.32867 11.6272 2.21986 11.0713 2.22214 10.5102C2.22443 9.94922 2.33777 9.39419 2.55563 8.87719C2.77348 8.36019 3.09155 7.89144 3.49148 7.49797L4.49306 6.49639L5.49464 7.49797L4.49306 8.49955C4.22995 8.76266 4.02125 9.07501 3.87885 9.41878C3.73646 9.76254 3.66317 10.131 3.66317 10.5031C3.66317 10.8752 3.73646 11.2436 3.87885 11.5874C4.02125 11.9311 4.22995 12.2435 4.49306 12.5066C4.75617 12.7697 5.06852 12.9784 5.41228 13.1208C5.75605 13.2632 6.12449 13.3365 6.49658 13.3365C6.86867 13.3365 7.23711 13.2632 7.58088 13.1208C7.92464 12.9784 8.23699 12.7697 8.5001 12.5066L9.50168 11.505L10.5033 12.5066ZM10.5033 5.4941L11.5056 6.49639L6.49693 11.5043L5.49464 10.5027L10.5033 5.4948V5.4941Z"
        fill="white"
      />
    </svg>
  );
};
export default LinkBlue;
