import React from "react";

const SocialBlue = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4733 9.73925H12.4778L12.3662 10.1857H11.4733V12.1946H11.0269V10.1857H10.134V9.73925H11.0269V9.32139C11.0269 8.9234 11.0684 8.77899 11.1461 8.63345C11.2222 8.48966 11.3398 8.37207 11.4836 8.29595C11.6291 8.21828 11.7735 8.17676 12.1715 8.17676C12.288 8.17676 12.3903 8.18792 12.4778 8.21024V8.62318H12.1715C11.876 8.62318 11.786 8.64059 11.6943 8.6897C11.6264 8.72586 11.576 8.77631 11.5398 8.84416C11.4907 8.9359 11.4733 9.02586 11.4733 9.32139V9.73925Z"
        fill="white"
      />
      <path
        d="M4.48689 2.38226C4.31724 2.38223 4.15436 2.4488 4.0333 2.56765C3.91224 2.6865 3.84267 2.84812 3.83957 3.01775L3.83332 3.36931C3.83296 3.38818 3.8286 3.40677 3.82055 3.42384C3.8125 3.44092 3.80092 3.4561 3.78659 3.46838C3.77226 3.48067 3.75549 3.48979 3.73738 3.49514C3.71928 3.5005 3.70025 3.50196 3.68154 3.49944L3.3331 3.45212C2.87462 3.38962 2.43534 3.17846 2.01392 2.82735C1.88044 3.56618 2.14115 4.07801 2.76904 4.47287L3.159 4.71796C3.17753 4.7296 3.19292 4.74561 3.20383 4.76457C3.21474 4.78354 3.22084 4.8049 3.22158 4.82677C3.22233 4.84864 3.21771 4.87036 3.20812 4.89003C3.19854 4.9097 3.18427 4.92672 3.16659 4.93961L2.81123 5.1992C3.02261 5.21237 3.22328 5.203 3.3898 5.16996C4.44292 4.95969 5.14313 4.16729 5.14313 2.86016C5.14313 2.75346 4.91724 2.38226 4.48689 2.38226V2.38226ZM3.39315 3.00949C3.39704 2.79432 3.46433 2.58509 3.58657 2.40798C3.70882 2.23087 3.8806 2.09376 4.0804 2.01382C4.2802 1.93387 4.49914 1.91465 4.70982 1.95855C4.9205 2.00244 5.11354 2.10752 5.26478 2.26061C5.42349 2.25949 5.55853 2.29967 5.86054 2.11664C5.78576 2.4827 5.74893 2.64163 5.58956 2.86016C5.58956 4.56595 4.54113 5.39541 3.4773 5.60768C2.74784 5.75322 1.68713 5.51416 1.38312 5.19675C1.53803 5.18469 2.16749 5.11706 2.53132 4.85077C2.22351 4.64787 0.998299 3.92689 1.80343 1.98851C2.18133 2.4298 2.56458 2.73025 2.95297 2.88962C3.21145 2.99565 3.27484 2.99342 3.39337 3.00971L3.39315 3.00949Z"
        fill="white"
      />
      <rect
        x="0.35"
        y="0.422266"
        width="6.79994"
        height="6.79994"
        rx="1.65"
        stroke="white"
        strokeWidth="0.7"
      />
      <rect
        x="7.85006"
        y="6.85098"
        width="6.79994"
        height="6.79994"
        rx="1.65"
        stroke="white"
        strokeWidth="0.7"
      />
      <rect
        x="8.92147"
        y="0.422266"
        width="4.6571"
        height="4.6571"
        rx="0.65"
        stroke="white"
        strokeWidth="0.7"
      />
      <rect
        x="1.42141"
        y="8.99355"
        width="4.6571"
        height="4.6571"
        rx="0.65"
        stroke="white"
        strokeWidth="0.7"
      />
    </svg>
  );
};
export default SocialBlue;
