import React from "react";

const MeesaIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24">
      <path d="M2.40039 6C2.40039 5.68174 2.52682 5.37651 2.75186 5.15147C2.97691 4.92643 3.28213 4.8 3.60039 4.8H14.4004C14.7186 4.8 15.0239 4.92643 15.2489 5.15147C15.474 5.37651 15.6004 5.68174 15.6004 6C15.6004 6.31826 15.474 6.62348 15.2489 6.84853C15.0239 7.07357 14.7186 7.2 14.4004 7.2H3.60039C3.28213 7.2 2.97691 7.07357 2.75186 6.84853C2.52682 6.62348 2.40039 6.31826 2.40039 6ZM2.40039 12C2.40039 11.6817 2.52682 11.3765 2.75186 11.1515C2.97691 10.9264 3.28213 10.8 3.60039 10.8H14.4004C14.7186 10.8 15.0239 10.9264 15.2489 11.1515C15.474 11.3765 15.6004 11.6817 15.6004 12C15.6004 12.3183 15.474 12.6235 15.2489 12.8485C15.0239 13.0736 14.7186 13.2 14.4004 13.2H3.60039C3.28213 13.2 2.97691 13.0736 2.75186 12.8485C2.52682 12.6235 2.40039 12.3183 2.40039 12ZM2.75186 17.1515C2.52682 17.3765 2.40039 17.6817 2.40039 18C2.40039 18.3183 2.52682 18.6235 2.75186 18.8485C2.97691 19.0736 3.28213 19.2 3.60039 19.2H14.4004C14.7186 19.2 15.0239 19.0736 15.2489 18.8485C15.474 18.6235 15.6004 18.3183 15.6004 18C15.6004 17.6817 15.474 17.3765 15.2489 17.1515C15.0239 16.9264 14.7186 16.8 14.4004 16.8H3.60039C3.28213 16.8 2.97691 16.9264 2.75186 17.1515Z" />
      <path d="M18 6C18 5.68174 18.1264 5.37651 18.3515 5.15147C18.5765 4.92643 18.8817 4.8 19.2 4.8H20.4C20.7183 4.8 21.0235 4.92643 21.2485 5.15147C21.4736 5.37651 21.6 5.68174 21.6 6C21.6 6.31826 21.4736 6.62348 21.2485 6.84853C21.0235 7.07357 20.7183 7.2 20.4 7.2H19.2C18.8817 7.2 18.5765 7.07357 18.3515 6.84853C18.1264 6.62348 18 6.31826 18 6ZM18 12C18 11.6817 18.1264 11.3765 18.3515 11.1515C18.5765 10.9264 18.8817 10.8 19.2 10.8H20.4C20.7183 10.8 21.0235 10.9264 21.2485 11.1515C21.4736 11.3765 21.6 11.6817 21.6 12C21.6 12.3183 21.4736 12.6235 21.2485 12.8485C21.0235 13.0736 20.7183 13.2 20.4 13.2H19.2C18.8817 13.2 18.5765 13.0736 18.3515 12.8485C18.1264 12.6235 18 12.3183 18 12ZM18.3515 17.1515C18.1264 17.3765 18 17.6817 18 18C18 18.3183 18.1264 18.6235 18.3515 18.8485C18.5765 19.0736 18.8817 19.2 19.2 19.2H20.4C20.7183 19.2 21.0235 19.0736 21.2485 18.8485C21.4736 18.6235 21.6 18.3183 21.6 18C21.6 17.6817 21.4736 17.3765 21.2485 17.1515C21.0235 16.9264 20.7183 16.8 20.4 16.8H19.2C18.8817 16.8 18.5765 16.9264 18.3515 17.1515Z" />
    </svg>
  );
};
export default MeesaIcon;
