import React from "react";

const AnalyticIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 24 24">
      <path d="M9,15a1,1,0,0,1-1-1V12a1,1,0,0,1,2,0v2A1,1,0,0,1,9,15Z" />
      <path d="M15,15a1,1,0,0,1-1-1V12a1,1,0,0,1,2,0v2A1,1,0,0,1,15,15Z" />
      <path d="M6,8a1,1,0,0,1-.71-.29l-3-3A1,1,0,0,1,3.71,3.29l3,3a1,1,0,0,1,0,1.42A1,1,0,0,1,6,8Z" />
      <path d="M18,8a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l3-3a1,1,0,1,1,1.42,1.42l-3,3A1,1,0,0,1,18,8Z" />
      <path d="M21,20H3a1,1,0,0,1-1-1V14.5a10,10,0,0,1,20,0V19A1,1,0,0,1,21,20ZM4,18H20V14.5a8,8,0,0,0-16,0Z" />
    </svg>
  );
};
export default AnalyticIcon;
